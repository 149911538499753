import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import analytics from '@/segment';

export default function useQueryParams() {
  const searchParams = useSearchParams();

  useEffect(() => {
    const source = searchParams.get('src') || searchParams.get('utm_source');
    const attributionId = searchParams.get('gclid');
    const checkoutSettings = searchParams.get('checkout');
    const platform = searchParams.get('platform');
    const referrerUID = searchParams.get('referrerUID');
    const impactClickId = searchParams.get('irclickid');

    analytics.identify({ user_source: source });

    if (source) localStorage.setItem('attributionSource', source);
    if (attributionId) localStorage.setItem('attributionId', attributionId);
    if (checkoutSettings) localStorage.setItem('checkout', checkoutSettings);
    if (platform) localStorage.setItem('platform', platform);
    if (referrerUID) localStorage.setItem('referrerUID', referrerUID);
    if (impactClickId) localStorage.setItem('impactClickId', impactClickId);
  }, [searchParams]);
}
